/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App {
  /* / text-align: center;  */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 10px;
  background-color: #f0f0f0;
}

.header__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.header__logo img {
  height: 50px;
}

.header__welcome {
  margin-right: auto;
  padding: 20px;
}

.header__logout img {
  cursor: pointer;
  height: 30px;
}

button.active, button.active:hover {
  background-color:  #99ccff;
  padding-right: 5px !important;
  
}
  .filter-date {
    display: flex;
    padding:30px;
  }
  .filter-date span {
    padding-right: 30px;
  }

  h2 {
    /* margin-left: 30px; */
    margin-right: 30px;
  }