.parent-div .MuiButtonGroup-root {
    height: fit-content;
    padding: 8px 0 0;
}
.parent-div {
    display: flex;
    justify-content: space-between;
}

.date-picker-div {
    display: flex;
    /* padding: 30px; */
    justify-content: center;
    align-items: center;
    .MuiTextField-root {
        min-width: 90px !important;
        width: 230px !important;
      }
}
.date-picker-div p {

    padding-right: 30px;
}