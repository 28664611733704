body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.active, button.active:hover {
  background-color:  #99ccff;
  padding-right: 5px !important;
}

.heading-title {
  border-bottom: 2px solid black;
  width: fit-content;
}

.file-upload {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  margin-top: 30px;
  p {
    text-align: center;
  }
  input {
    text-align-last: center;
  }
}
.search-row {
  display: flex;
  align-items: center;
}
.parent-div {
  margin-bottom: 30px;
  margin-top: 30px;
}
.search-header {
    padding-right: 0px;

  h2 {
    margin-left: 0;
    border-bottom: 2px solid black;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .parent-div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .search-row {
    padding-top: 30px;
    justify-content: center;
  }
}

.MuiTableContainer-root {
  table {
    tr {
      th {
        padding: 12px !important;
        background-color: #1976d2 !important;
        color: white !important;
      }
      td {
        padding: 8px !important;
      }
    }
  }
}
.MuiContainer-root {
  .MuiContainer-root {
    padding: 0;
  }
}
.MuiTabs-scroller {
  .MuiTabs-indicator {
    background-color: white;
  }
}
.upload-date-picker-div {
  display: flex;
  /* padding-left: 30px; */
  padding-bottom: 30px;
  p {
    padding-right: 30px;
  }
  .MuiTextField-root {
    min-width: 90px !important;
    width: 250px !important;
  }
}

.footer {
  background-color: #f8f9fa;
  /* position: fixed; */
  /* bottom: 0; */
  /* width: 100%; */
  padding-left: 30px;
}

.text-muted {
  color: #6c757d;
}

.customers-search-icon {
  display:flex;
  justify-content: center;
  align-items: center;
}
.customers-home-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
header {
  border-bottom: 8px solid #1976d2;
  background-color: white !important;
  color: black !important;
  button {
    svg {
    color: black !important;
    }
  }

}
#customized-dialog-title {
    border-bottom: none !important;
}
