.button-row {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
}

/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
} */

.modal {
  position:fixed;
  background: white;
  width: 60%;
  height: 40%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  border: black solid 0.5px;
}

.modal #close-btn {
  position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 10px;
        background-color: white; 
        color: black;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        font-size: 14px;
}

.toggle-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  /* height: 100vh; Center vertically */
}
.upload-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #99ccff;; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  
}

/* Styling for the file input element */
.upload-btn input[type="file"] {
  display: none;
}