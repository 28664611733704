.search-row {
    display: flex;
}

.seach-input {
    display: flex;
}
.search {
    display: flex;
    padding: 30px;
    align-items: center;
}
